/** @jsx jsx */
import { jsx, Box, Button, Container, Flex  } from "theme-ui"
import { graphql } from "gatsby"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import React, { useState } from 'react';

import {
  Layout,
  Main,
  Section,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/layout"

import { BlogHero, SelectDropdownAuthor, SelectDropdownTopic } from "../../components/elements"
import PostCard from "../../components/elements/PostCard"
import { groupByAuthor } from "../../utils/groupByAuthor";
import { groupByTopic } from "../../utils/groupByTopic";

const Blog = ({ data, pageContext }) => {
  const {
    breadcrumb: { crumbs },
  } = pageContext

  const [authorItem, setauthorItem] = useState(' ');
  const [tagItem, settagItem] = useState(' ');

  const handleReset = () => {
    setauthorItem(' ');
    settagItem(' ');
  };

  const handleAuthorReset = () => {
    setauthorItem(' ');
    
  };

  const handleTopicReset = () => {
    settagItem(' ');
  };

  const posts = data?.allDatoCmsPost?.edges

  // Collect, organize, and sort post tags
  const allTags = posts
    .map(edge => edge.node.tags)  // Extract tags from each node
    .reduce((acc, tags) => acc.concat(tags), [])  // Flatten the array of arrays
    .map(tag => ({ slug: tag.slug, title: tag.title }));  // Create a new object for each tag with slug and title
  
  const uniqueTags = allTags.reduce((acc, current) => {
      const identifier = `${current.slug}-${current.title}`;
      if (!acc.seen[identifier]) {
          acc.seen[identifier] = true;
          acc.result.push(current);
      }
      return acc;
  }, { seen: {}, result: [] }).result;

  const tagsSorted = uniqueTags.sort((a, b) => a.title.localeCompare(b.title));


  // Collect, organize, and sort post authors
  const allAuthors = posts.map(edge => edge.node.author).map(author => ({ slug: author.slug, title: author.name }))
  
  const uniqueAuthors = allAuthors.reduce((acc, current) => {
      const identifier = `${current.slug}-${current.title}`;
      if (!acc.seen[identifier]) {
          acc.seen[identifier] = true;
          acc.result.push(current);
      }
      return acc;
  }, { seen: {}, result: [] }).result;

  const authorsSorted = uniqueAuthors.sort((a, b) => a.title.localeCompare(b.title));
  
  

  return (
    <Layout data-datocms-noindex>
      {/* <HelmetDatoCms seo={page.seoMetaTags} /> */}
      <Main>
        {/* Hero */}
        <BlogHero>
              <Breadcrumb
                crumbs={crumbs}
                crumbSeparator=" / "
                crumbLabel="Blog"
              /> 
          
        </BlogHero>

        {/* IntroContent */}
        <Section id="intro">
          <Container sx={{ px: "1em" }}>

            <Flex sx={{ width: 'full'}}>
              <Box as="form">
                <Flex sx={{ flexWrap:'wrap'}}>
                  <Box sx={{ m: 1, w: ['full', 'full', 1/3]}}>
                    <SelectDropdownTopic
                      options={tagsSorted}
                      settagItem={settagItem}
                      tagItem={tagItem}
                      defaultValue="Filter by Topic"
                    /> 
                  </Box>
                  
                  <Box sx={{ m: 1, w: ['full', 'full', 1/3]}}>
                    <SelectDropdownAuthor
                      options={authorsSorted}
                      setauthorItem={setauthorItem}
                      authorItem={authorItem} defaultValue="Filter by Author"
                      
                    />
                  </Box>
                </Flex>
                
                <Button
                  type='reset'
                  value='reset'
                  onClick={handleReset}
                  sx={{ m: 1}}
                  >
                    Reset
                  </Button>
              </Box>
            </Flex>
            
            <Flex sx={{ flexWrap: "wrap", mx: [0, 0, -2] }}>

              
              {authorItem !== ' '  ?

                groupByAuthor(posts, authorItem)?.map(({ node: post }) => (
                  <PostCard key={post?.id} post={post} width={["full", "full", "1/3"]} />
                )) :

                groupByTopic(posts, tagItem)?.map(({ node: post }) => (
                  <PostCard key={post?.id} post={post} width={["full", "full", "1/3"]} />
                ))}
              
            </Flex>
          </Container>
        </Section>
      </Main>
    </Layout>
  )
}

export default Blog

export const query = graphql`{
  allDatoCmsPost(sort: {fields: date, order: DESC}, filter: {meta: {status: {eq: "published"}}}) {
    edges {
      node {
        author {
          slug
          name
        }
        title
        slug
        date(formatString: "MM/d/yyyy")
        id
        meta {
          status
        }
        blogImage {
          fluid(
                maxWidth: 240
                imgixParams: { fm: "jpg", auto: "compress" }
              ) {
                ...GatsbyDatoCmsFluid
          }
        }
        tags {
      ... on DatoCmsUnit {
        id
        title
        slug
      }
      ... on DatoCmsProgram {
        id
        title
        slug
      }
      ... on DatoCmsCenterOfExcellence {
        id
        title
        slug
      }
      ... on DatoCmsChildrensService {
        id
        title
        slug
      }
      ... on DatoCmsChildrensProgram {
        id
        slug
        title
      }
    }
      }
    }
  }
}

`

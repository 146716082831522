


export function groupByTopic(array, string) {
  if (string == ' ' || string == 'Filter by Topic') {
    return array;
  } else {
    
    return array.filter(item => item.node.tags.some(item => item?.title == string));
  }
}
